
<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                  <div class="row">
                      <div class="col-sm-6 pb-3">
                        <h5 class="card-title">Transactions List</h5>
                      </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    <span class="mr-1">Display</span>
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                          <div class="form-inline float-sm-right">
                            <div id="tickets-table_filter" class="dataTables_filter mr-sm-2 text-md-right">
                                <label class="d-inline-flex mb-0 align-items-center">
                                    <span class="mr-2 d-none d-sm-inline">Search:</span>
                                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                                </label>
                            </div>
                            <b-dropdown variant="light" size="sm" right>
                              <template slot="button-content">
                                 <i class="mdi mdi-filter-variant"></i>
                              </template>
                              <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                              <b-dropdown-item-button @click="statusUpdate()" ><i class="mdi mdi-cash-refund"></i> Update Status</b-dropdown-item-button>
                              <b-dropdown-item-button @click="deleteTransaction()" ><i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                          </div>
                        </div>
                    </div>

                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class="" ref="selectableTable" :items="transactions" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            <template v-slot:cell(check)="data">
                                <div class="custom-control custom-checkbox text-center">
                                    <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                                    <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                                </div>
                            </template>
                            <template v-slot:cell(card)="data">
                                <div class="d-inline-block cursor-pointer align-middle">
                                    <p class="text-dark mb-1 font-sm">{{ data.item.account_name }}</p>
                                    <p class="text-dark mb-1 font-sm">{{ data.item.account_number }}</p>
                                    <p class="text-dark mb-1 font-sm">{{ data.item.bank_name }}</p>
                                    <p class="mb-0 font-sm"><router-link :to="`/users/${data.item.worker_id}`">view worker</router-link></p>
                                </div>
                            </template>
                            <template v-slot:cell(status)="data">
                                <div class="d-inline-block cursor-pointer align-middle">
                                    <p class="text-dark mb-1 font-sm">{{ data.item.status }}</p>
                                </div>
                            </template>
                            <template v-slot:cell(amount)="data">
                                <div class="d-inline-block cursor-pointer align-middle">
                                    <p class="text-dark mb-1 font-sm">${{ data.item.amount }}</p>
                                    <p class="text-dark mb-1 font-sm">{{ data.item.exchange_rate }}</p>
                                </div>
                            </template>
                            <template v-slot:cell(date)="data">
                                <span class="cursor-pointer">{{data.item.created_at | date_ago}} </span>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <b-modal size="md" title="Transaction Request"
        body-bg-variant="light" body-class="p-3"
          hide-footer v-model="statusObj.modal">
        <div>
          <b-alert show dismissible fade variant="warning">
            <h4 class="alert-heading">Be sure!</h4>
            <p> You have selected {{statusObj.count}} transaction{{statusObj.count > 1 ? 's':''}} to be updated</p>
          </b-alert>
          <div class="form-group mb-3">
            <label class="">Select Status</label>
            <select class="custom-select" v-model="statusObj.action" placeholder="--select status--">
                <option disabled :value="null" key="-1">-- choose status --</option>
                <option :value="status.action" v-for="(status, sIndex) in statusObj.list" :key="sIndex">{{status.label}}</option>
            </select>
          </div>
            <div class="form-group text-right mt-3">
              <button class="btn btn-primary" @click.prevent="updateStatus()"  :disabled="statusObj.action ? false : true" type="button">Update</button>
            </div>
        </div>
    </b-modal>
</div>

</template>

<script>

export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "Card",
            label: "Card details",
            sortable: true
          },
          {
              key: "status",
              label: "Status",
              sortable: true
          },
          {
            key: "Amount",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },

      ],
      transactions:[],
      statusObj:{
        modal: false,
        count: 0,
        action: null,
        list: [
          { action: 'paid', label: 'Mark As Paid'},
          { action: 'pending', label: 'Mark Pending'},
          { action: 'cancelled', label: 'Cancel'},
        ] 
      }
    }
  },
  computed: {
    rows() {
        return this.transactions.length;
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.transactions.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    statusUpdate(){
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      this.statusObj.count = this.selected.length;
      this.statusObj.modal = true;
    },
    fetchTransactions() {
        this.$http.get('/transactions')
        .then(response => {
            if(response.data.success){
              this.transactions = [...response.data.data.transactions]
            }
        })
    },
    updateStatus() {
        const formData = new FormData()
        formData.append('data', JSON.stringify({items: this.selected, action: this.statusObj.action }))
        this.$store.dispatch("changeLoaderValue",true)
        this.$http.post('/transactions/update',formData)
        .then(response => {
          this.$store.dispatch("changeLoaderValue",false)
          if(response.data.success){
            this.statusObj.modal = false;
            this.fetchTransactions()
          }
        })
    },
    deleteTransaction() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} transaction${this.selected.length > 1 ? 's':'' }`;
      Swal.fire({
        title: "Are you sure?",
        text: text,
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/transactions/delete', formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.fetchTransactions()
            }
          })
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted() {
    this.totalRows = this.transactions.length;
  },
  created(){
    this.fetchTransactions()
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
